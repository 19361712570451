@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: 'Comfortaa', cursive,'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}



.circle-wrap {
  width: 30px;
  height: 30px;
  border-radius: 50% 0  50%  50% ;
  transform: rotate(135deg);
  background: #000000;
  
}
.progressbar {
 
    --bg: #def;
    --pgPercentage: var(--value);
    width: 30px;
    height: 30px;
    border-radius: 100% ;
    transform: rotate(45deg);
    border-width: 0;
    border-color: #000000;
    background:   conic-gradient(var(--soc-color) calc(var(--pgPercentage) * 1%) , var(--bg) 0)  ;
 
  }

  .progressbar::after {
    content: '';
    width: 24px;
       height: 24px;
       margin: 3px 0 0 3px;
       background: #fff;
       position: absolute;
       border-radius: 50%;
  }

  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }

  
#icon {
    position: absolute;
       width: 20px;
       z-index: 100;
       font-size: 20px;
       transform: rotate(180deg);
       left: 0;
       right: 0;
       margin: 5px auto;
       text-align: center;
}


.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
 .marker-pin::after {
     content: '';
     width: 24px;
     height: 24px;
     margin: 3px 0 0 3px;
     background: #fff;
     position: absolute;
     border-radius: 50%;
 }

 .custom-div-icon i {
     position: absolute;
     width: 20px;
     font-size: 20px;
     left: 0;
     right: 0;
     margin: 2px auto;
     text-align: center;
 }

